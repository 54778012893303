import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Context } from '../utils/context'
import styles from './Settings.module.css'
import {
    MdOutlineEdit,
    MdOutlineDelete,
    MdPlayArrow,
    MdOutlinePause,
} from 'react-icons/md'
import { IoMdClose } from 'react-icons/io'
import {
    createAccount,
    deleteAccount,
    getProviders,
    toggleAccountStatus,
    updateAccount,
} from '../services/api'
import {
    ACCOUNT_STATUS,
    AccountActivityIF,
    AccountIF,
    ProviderIF,
} from '../utils/types'
import Loader from 'react-spinners/BounceLoader'
import { RiVerifiedBadgeLine } from 'react-icons/ri'
import { Dropdown } from '../components/Dropdown'
import { Modal } from '../components/Modal'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import { TbEye, TbEyeOff, TbEyeSearch } from 'react-icons/tb'
import { formatNumber, getAccountName } from '../utils/bets'
import { UTCToEST } from '../utils/timezone'
import { supportedProxyCities } from '../utils/accounts'
import { AccountDownloadModal } from './AccountDownloadModal'

export const Accounts = () => {
    const { accounts } = useContext(Context)
    const [accountToEdit, setAccountToEdit] = useState<AccountIF | null>(null)
    const [accountModalAction, setAccountModalAction] = useState<{
        account: AccountIF
        action: 'DELETE' | 'VIEW_LOGS'
    } | null>(null)
    const [showAccountsDownloadModal, setShowAccountDownModal] =
        useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sort, setSort] = useState<{
        key:
            | 'status'
            | 'provider'
            | 'website'
            | 'source'
            | 'username'
            | 'autobet'
        order: number
    }>({ key: 'status', order: 1 })
    const [showArchived, setShowArchived] = useState(false)
    const [search, setSearch] = useState('')

    const accountsToShow = useMemo(() => {
        return accounts.filter((a) => {
            if (!showArchived && a.status === ACCOUNT_STATUS.ARCHIVED)
                return false
            if (search === '') return true
            const regex = new RegExp(`^${search}$`, 'i')
            return [
                a.website,
                a.accountProviders.map((ap) => ap.provider.name).join(', '),
                a.source ?? '',
                a.username,
                a.password,
                a.family ?? '',
                a.proxyCity ?? '',
                a.proxyState ?? '',
                a.liability?.toString() ?? '',
                a.autoBetAmount.toString(),
                a.tags.map((tag) => tag).join(', '),
                a.currency ?? '',
            ].some((x) => regex.test(x))
        })
    }, [accounts, search, showArchived])

    const onConfirmDeleteAccount = useCallback(async (id: number) => {
        setIsLoading(true)
        await deleteAccount(id)
        setAccountModalAction(null)
        setIsLoading(false)
    }, [])

    const sortArrows = useCallback((order: number, show: boolean) => {
        return order > 0 ? (
            <BiDownArrowAlt
                size={16}
                style={{
                    opacity: show ? '1' : '0',
                }}
            />
        ) : (
            <BiUpArrowAlt
                size={16}
                style={{
                    opacity: show ? '1' : '0',
                }}
            />
        )
    }, [])

    const getAccountBetTypes = useCallback(
        (betTypes: string[], betIntervals: string[], betLeagues: string[]) => {
            if (
                betTypes.length === SUPPORTED_BET_TYPES.length &&
                betIntervals.length === SUPPORTED_BET_INTERVALS.length &&
                betLeagues.length === SUPPORTED_BET_LEAGUES.length
            )
                return 'All'
            let arr = []
            if (betTypes.length === SUPPORTED_BET_TYPES.length)
                arr.push('All Types')
            else if (betTypes.length === 1) arr.push(`Only ${betTypes[0]}`)
            else if (betTypes.length > 1)
                arr.push(
                    `No ${SUPPORTED_BET_TYPES.filter(
                        (t) => !betTypes.includes(t)
                    ).join(', ')}`
                )

            if (betIntervals.length === SUPPORTED_BET_INTERVALS.length)
                arr.push('All Intervals')
            else if (betIntervals.length === 1)
                arr.push(`Only ${betIntervals[0]}`)
            else if (betIntervals.length > 1)
                arr.push(
                    `No ${SUPPORTED_BET_INTERVALS.filter(
                        (t) => !betIntervals.includes(t)
                    ).join(', ')}`
                )

            if (betLeagues.length === SUPPORTED_BET_LEAGUES.length)
                arr.push('All Leagues')
            else if (betLeagues.length === 1) arr.push(`Only ${betLeagues[0]}`)
            else if (betLeagues.length > 1)
                arr.push(
                    `No ${SUPPORTED_BET_LEAGUES.filter(
                        (t) => !betLeagues.includes(t)
                    ).join(', ')}`
                )

            return arr.join(', ')
        },
        []
    )

    return (
        <div className={styles.settings}>
            <div className={styles.header}>
                <header>
                    Accounts {accountsToShow && `(${accountsToShow.length})`}
                </header>
                {accountToEdit === null && (
                    <input
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search..."
                    />
                )}
            </div>
            {accountToEdit === null ? (
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.title_container}>
                            Account Management
                            {showArchived ? (
                                <TbEyeOff
                                    title="Hide Archived Accounts"
                                    className={styles.icon}
                                    size={18}
                                    onClick={() => setShowArchived(false)}
                                />
                            ) : (
                                <TbEye
                                    title="Show Archived Accounts"
                                    className={styles.icon}
                                    size={18}
                                    onClick={() => setShowArchived(true)}
                                />
                            )}
                        </div>
                        <div className={styles.account_button_group}>
                            <button
                                onMouseDown={() =>
                                    setAccountToEdit({
                                        autoBetAmount: 0,
                                    } as AccountIF)
                                }
                            >
                                Add Account
                            </button>
                            <button
                                onMouseDown={() =>
                                    setShowAccountDownModal(true)
                                }
                                disabled={
                                    !accountsToShow ||
                                    accountsToShow.length === 0
                                }
                            >
                                Download Accounts
                            </button>
                        </div>
                    </div>
                    <div className={styles.section_content}>
                        {isLoading ? (
                            <Loader
                                loading={isLoading}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        ) : accountsToShow && accountsToShow.length > 0 ? (
                            <div className={styles.table_container}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'status'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'status',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span
                                                    className={styles.sortable}
                                                    style={{ width: '20px' }}
                                                ></span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'status'
                                                )}
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'website'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'website',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Site</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'website'
                                                )}
                                            </td>
                                            <td>
                                                <span>Provider</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'provider'
                                                )}
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'source'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'source',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Source</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'source'
                                                )}
                                            </td>
                                            <td>
                                                <span>Sub-Source</span>
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'username'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'username',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Username</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'username'
                                                )}
                                            </td>
                                            <td>Password</td>
                                            <td>Family</td>
                                            <td>City</td>
                                            <td>Liability</td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'autobet'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'autobet',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>AutoBet</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'autobet'
                                                )}
                                            </td>
                                            <td>Max Win</td>
                                            <td>Bet Type</td>
                                            <td>Tags</td>
                                            <td>Currency</td>
                                            <td className={styles.sticky}></td>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.scrollable}>
                                        {accountsToShow
                                            .sort((a, b) => {
                                                switch (sort.key) {
                                                    case 'status':
                                                        const statusOrder = {
                                                            [ACCOUNT_STATUS.ACTIVE]: 1,
                                                            [ACCOUNT_STATUS.INACTIVE]: 2,
                                                            [ACCOUNT_STATUS.DISABLED_MAX_WIN]: 3,
                                                            [ACCOUNT_STATUS.LOGIN_ERROR]: 4,
                                                            [ACCOUNT_STATUS.ERROR]: 5,
                                                            [ACCOUNT_STATUS.ARCHIVED]: 6,
                                                        }
                                                        const order = (
                                                            account: AccountIF
                                                        ) => {
                                                            if (
                                                                account.isScraperAccount
                                                            )
                                                                return 7
                                                            return statusOrder[
                                                                account.status
                                                            ]
                                                        }
                                                        return (
                                                            (order(a) -
                                                                order(b)) *
                                                            sort.order
                                                        )
                                                    case 'website':
                                                        return (
                                                            getAccountName(
                                                                a.website
                                                            ).localeCompare(
                                                                getAccountName(
                                                                    b.website
                                                                )
                                                            ) * sort.order
                                                        )
                                                    case 'source':
                                                        return (
                                                            (
                                                                a.source || ''
                                                            ).localeCompare(
                                                                b.source || ''
                                                            ) * sort.order
                                                        )
                                                    case 'username':
                                                        return a.username &&
                                                            b.username
                                                            ? a.username.localeCompare(
                                                                  b.username
                                                              ) * sort.order
                                                            : 0
                                                    case 'autobet':
                                                        return a.autoBetAmount &&
                                                            b.autoBetAmount
                                                            ? a.autoBetAmount
                                                                  .toString()
                                                                  .localeCompare(
                                                                      b.autoBetAmount.toString()
                                                                  ) * sort.order
                                                            : 0
                                                    default:
                                                        return 0
                                                }
                                            })
                                            .map((a) => (
                                                <tr key={a.id}>
                                                    <td
                                                        className={styles.icons}
                                                    >
                                                        {!a.isScraperAccount && (
                                                            <RiVerifiedBadgeLine
                                                                title={a.status}
                                                                color={
                                                                    a.status ===
                                                                    ACCOUNT_STATUS.ACTIVE
                                                                        ? 'var(--success)'
                                                                        : a.status ===
                                                                          ACCOUNT_STATUS.INACTIVE
                                                                        ? 'var(--warn)'
                                                                        : a.status ===
                                                                          ACCOUNT_STATUS.DISABLED_MAX_WIN
                                                                        ? '#cd672c'
                                                                        : a.status ===
                                                                          ACCOUNT_STATUS.ARCHIVED
                                                                        ? 'black'
                                                                        : 'var(--danger)'
                                                                }
                                                                size={18}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <a
                                                            href={a.website}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {a.website}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {a.accountProviders
                                                            .map(
                                                                (ap) =>
                                                                    ap.provider
                                                                        .name
                                                            )
                                                            .join(', ')}
                                                    </td>
                                                    <td>{a.source}</td>
                                                    <td>{a.subSource}</td>
                                                    <td>{a.username}</td>
                                                    <td>{a.password}</td>
                                                    <td>{a.family}</td>
                                                    <td>
                                                        {a.proxyState ? `${a.proxyState} | ` : ''}
                                                        {a.proxyCity}
                                                    </td>
                                                    <td>
                                                        {formatNumber(
                                                            (a.liability || 0) *
                                                                100
                                                        )}
                                                    </td>
                                                    <td>{a.autoBetAmount}</td>
                                                    <td>{a.winTotal ?? '-'}</td>
                                                    <td
                                                        title={getAccountBetTypes(
                                                            a.supportedBetTypes,
                                                            a.supportedBetIntervals,
                                                            a.supportedBetLeagues
                                                        )}
                                                    >
                                                        {getAccountBetTypes(
                                                            a.supportedBetTypes,
                                                            a.supportedBetIntervals,
                                                            a.supportedBetLeagues
                                                        )}
                                                    </td>
                                                    <td
                                                        title={a.tags.join(
                                                            ', '
                                                        )}
                                                    >
                                                        {a.tags.join(', ')}
                                                    </td>
                                                    <td>{a.currency ?? ''}</td>
                                                    <td
                                                        className={
                                                            styles.sticky
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.icons
                                                            }
                                                        >
                                                            <TbEyeSearch
                                                                className={
                                                                    styles.icon
                                                                }
                                                                size={18}
                                                                onClick={() =>
                                                                    setAccountModalAction(
                                                                        {
                                                                            account:
                                                                                a,
                                                                            action: 'VIEW_LOGS',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                            {a.status ===
                                                            ACCOUNT_STATUS.ACTIVE ? (
                                                                <MdOutlinePause
                                                                    className={
                                                                        styles.icon
                                                                    }
                                                                    size={18}
                                                                    onClick={async () => {
                                                                        await toggleAccountStatus(
                                                                            a.id,
                                                                            ACCOUNT_STATUS.INACTIVE
                                                                        )
                                                                        a.status = ACCOUNT_STATUS.INACTIVE
                                                                    }}
                                                                />
                                                            ) : (
                                                                <MdPlayArrow
                                                                    className={
                                                                        styles.icon
                                                                    }
                                                                    size={18}
                                                                    onClick={async () => {
                                                                        await toggleAccountStatus(
                                                                            a.id,
                                                                            ACCOUNT_STATUS.ACTIVE
                                                                        )
                                                                        a.status = ACCOUNT_STATUS.ACTIVE
                                                                    }}
                                                                />
                                                            )}
                                                            <MdOutlineEdit
                                                                className={
                                                                    styles.icon
                                                                }
                                                                size={18}
                                                                onClick={() =>
                                                                    setAccountToEdit(
                                                                        a
                                                                    )
                                                                }
                                                            />
                                                            <MdOutlineDelete
                                                                className={
                                                                    styles.icon
                                                                }
                                                                size={18}
                                                                onClick={() =>
                                                                    setAccountModalAction(
                                                                        {
                                                                            account:
                                                                                a,
                                                                            action: 'DELETE',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No accounts found.</p>
                        )}
                    </div>
                </div>
            ) : (
                <EditAccount
                    account={accountToEdit}
                    setAccount={setAccountToEdit}
                    sources={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.source))
                        ).filter((s) => s) as string[]
                    }
                    subSources={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.subSource))
                        ).filter((s) => s) as string[]
                    }
                    families={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.family))
                        ).filter((s) => s) as string[]
                    }
                    states={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.proxyState))
                        ).filter((s) => s) as string[]
                    }
                    cities={
                        Array.from(
                            new Set(
                                accountsToShow
                                    ?.map((a) => a.proxyCity)
                                    .concat(supportedProxyCities)
                            )
                        ).filter((s) => s) as string[]
                    }
                />
            )}
            {accountModalAction &&
                accountModalAction.action === 'VIEW_LOGS' && (
                    <AccountActivityModal
                        logs={accountModalAction.account.activity}
                        onClose={() => setAccountModalAction(null)}
                    />
                )}
            {accountModalAction && accountModalAction.action === 'DELETE' && (
                <AccountModal
                    account={accountModalAction.account}
                    onClose={() => setAccountModalAction(null)}
                    onConfirm={() =>
                        onConfirmDeleteAccount(accountModalAction.account.id)
                    }
                    action="Delete"
                />
            )}
            {showAccountsDownloadModal && accountsToShow && (
                <AccountDownloadModal
                    accounts={accountsToShow}
                    onClose={() => setShowAccountDownModal(false)}
                />
            )}
        </div>
    )
}

const AccountModal = (props: {
    account: AccountIF
    onClose: () => void
    onConfirm: () => void
    action: string
}) => {
    const { account, onClose, onConfirm, action } = props
    return (
        <Modal onClose={onClose}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div className={styles.modal}>
                    <p>{`Are you sure you want to ${action.toLowerCase()} account ${
                        account.username
                    } on ${account.website}?`}</p>
                    <div className={styles.modal_button_row}>
                        <button onClick={onClose} type="button">
                            Cancel
                        </button>
                        <button
                            className={styles.danger}
                            onClick={onConfirm}
                            type="submit"
                        >
                            {action}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

const AccountActivityModal = (props: {
    logs: AccountActivityIF[]
    onClose: () => void
}) => {
    const { logs, onClose } = props
    return (
        <Modal onClose={onClose}>
            <div className={styles.modal}>
                <span>Account Activity</span>
                {logs && logs.length > 0 ? (
                    logs
                        .sort((a, b) =>
                            String(a.createdAt).localeCompare(
                                String(b.createdAt)
                            )
                        )
                        .map((log, i) => (
                            <div key={i} className={styles.log}>
                                <p>{`${log.activity} on ${UTCToEST(
                                    log.createdAt
                                )}`}</p>
                            </div>
                        ))
                ) : (
                    <p>No activity found.</p>
                )}
                <div className={styles.modal_button_row}>
                    <button onClick={onClose} type="button">
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    )
}

const SUPPORTED_BET_TYPES = ['Moneyline', 'Spread', 'Total']
const SUPPORTED_BET_INTERVALS = ['Game', 'Half', 'Segment']
const SUPPORTED_BET_LEAGUES = ['WNBA', 'CFL', 'NFL', 'CFB', 'NBA', 'CBB']

const EditAccount = (props: {
    subSources: string[]
    account: AccountIF
    setAccount: Dispatch<SetStateAction<AccountIF | null>>
    sources: string[]
    states: string[]
    families: string[]
    cities: string[]
}) => {
    const { account, setAccount } = props
    const [providers, setProviders] = useState<ProviderIF[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const isExistingAccount = !!account.id

    useEffect(() => {
        // populate default values for new account
        if (!isExistingAccount)
            setAccount({
                supportedBetTypes: SUPPORTED_BET_TYPES,
                supportedBetIntervals: SUPPORTED_BET_INTERVALS,
                supportedBetLeagues: SUPPORTED_BET_LEAGUES,
            } as AccountIF)
        ;(async () => {
            const res = await getProviders()
            if (res?.providers) setProviders(res?.providers)
        })()
    }, [isExistingAccount, setAccount])

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            if (!isExistingAccount) {
                await createAccount(account)
            } else {
                await updateAccount(account)
            }
            setAccount(null)
        } catch (e: any) {
            setError(
                e.message ||
                    `Unable to ${
                        isExistingAccount ? 'edit' : 'create'
                    } account. Please try again later.`
            )
        }
        setIsLoading(false)
    }

    return (
        <>
            <div className={styles.section_header}>
                {`${isExistingAccount ? 'Edit' : 'Add'} Account`}
                <IoMdClose
                    size={24}
                    className={styles.close}
                    onClick={() => setAccount(null)}
                />
            </div>
            <div className={styles.section_content} style={{ padding: '1rem' }}>
                <div className={styles.flex_container}>
                    <label>Website</label>
                    <input
                        type="text"
                        value={account.website}
                        onChange={(e) =>
                            setAccount({ ...account, website: e.target.value })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Username</label>
                    <input
                        type="text"
                        value={account.username}
                        onChange={(e) =>
                            setAccount({ ...account, username: e.target.value })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Password</label>
                    <input
                        type="text"
                        value={account.password}
                        onChange={(e) =>
                            setAccount({
                                ...account,
                                password: e.target.value,
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Provider</label>
                    <Dropdown
                        value={
                            account.accountProviders?.map(
                                (ap) => ap.provider.name
                            ) ?? undefined
                        }
                        options={providers.map((p) => p.name)}
                        onMultiSelect={(option) => {
                            if (!option) return
                            setAccount({
                                ...account,
                                accountProviders: option.map((o) => ({
                                    account,
                                    provider: providers.find(
                                        (p) => p.name === o
                                    ) ?? { id: 0, name: '' },
                                })),
                            })
                        }}
                        placeholder="Select a provider..."
                        search
                        multiple
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Family</label>
                    <Dropdown
                        value={account.family ?? undefined}
                        creatable
                        options={props.families}
                        onSelect={(option) =>
                            setAccount({
                                ...account,
                                family: option,
                            })
                        }
                        search
                        clear
                        placeholder="Select a family"
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Tags (separate by comma)</label>
                    <input
                        type="text"
                        value={account.tags && account.tags.join(', ')}
                        onChange={(e) =>
                            setAccount({
                                ...account,
                                tags: e.target.value
                                    .split(',')
                                    .map((t) => t.trim()),
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Source</label>
                    <Dropdown
                        value={account.source ?? undefined}
                        options={props.sources}
                        onSelect={(option) =>
                            setAccount({
                                ...account,
                                source: option,
                            })
                        }
                        placeholder="Select a source..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Sub-Source</label>
                    <Dropdown
                        value={account.subSource ?? undefined}
                        options={props.subSources}
                        onSelect={(option) =>
                            setAccount({
                                ...account,
                                subSource: option,
                            })
                        }
                        placeholder="Select a sub-source..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>State</label>
                    <Dropdown
                        value={account.proxyState ?? undefined}
                        options={props.states.sort((a, b) =>
                            a.localeCompare(b)
                        )}
                        onSelect={(option) => {
                            setAccount({
                                ...account,
                                proxyState: option,
                            })
                        }}
                        placeholder="Start typing to select a state..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>City</label>
                    <Dropdown
                        value={account.proxyCity ?? undefined}
                        options={props.cities.sort((a, b) =>
                            a.localeCompare(b)
                        )}
                        onSelect={(option) => {
                            setAccount({
                                ...account,
                                proxyCity: option,
                            })
                        }}
                        placeholder="Start typing to select a city..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Liability %</label>
                    <input
                        type="text"
                        value={
                            account.liability !== undefined
                                ? formatNumber(account.liability * 100)
                                : ''
                        }
                        onChange={(e) =>
                            (!e.target.value ||
                                (!isNaN(parseFloat(e.target.value)) &&
                                    parseFloat(e.target.value) <= 100)) &&
                            setAccount({
                                ...account,
                                liability:
                                    e.target.value === ''
                                        ? undefined
                                        : parseFloat(e.target.value) / 100,
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Autobet Amount</label>
                    <input
                        type="text"
                        value={
                            account.autoBetAmount !== 0
                                ? account.autoBetAmount
                                : ''
                        }
                        onChange={(e) =>
                            (!e.target.value ||
                                !isNaN(parseFloat(e.target.value))) &&
                            setAccount({
                                ...account,
                                autoBetAmount:
                                    e.target.value === ''
                                        ? 0
                                        : parseFloat(e.target.value),
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Max Win Amount</label>
                    <input
                        type="text"
                        value={
                            account.winTotal && account.winTotal !== 0
                                ? account.winTotal
                                : ''
                        }
                        onChange={(e) =>
                            (!e.target.value ||
                                !isNaN(parseFloat(e.target.value))) &&
                            setAccount({
                                ...account,
                                winTotal:
                                    e.target.value === ''
                                        ? undefined
                                        : parseFloat(e.target.value),
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Currency</label>
                    <Dropdown
                        value={account.currency ?? 'USD'}
                        options={['USD', 'CAD']}
                        onSelect={(option) => {
                            setAccount({
                                ...account,
                                currency: option,
                            })
                        }}
                        clear
                    />
                </div>
                <div className={`${styles.flex_container} ${styles.checkbox}`}>
                    <label>Bet Types</label>
                    <div className={styles.checkbox_container}>
                        {SUPPORTED_BET_TYPES.map((type) => (
                            <label key={type}>
                                <input
                                    type="checkbox"
                                    checked={account.supportedBetTypes?.includes(
                                        type
                                    )}
                                    onChange={(e) =>
                                        setAccount({
                                            ...account,
                                            supportedBetTypes: e.target.checked
                                                ? [
                                                      ...account.supportedBetTypes,
                                                      type,
                                                  ]
                                                : account.supportedBetTypes.filter(
                                                      (t) => t !== type
                                                  ),
                                        })
                                    }
                                />
                                <span>{type}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={`${styles.flex_container} ${styles.checkbox}`}>
                    <label>Bet Intervals</label>
                    <div className={styles.checkbox_container}>
                        {SUPPORTED_BET_INTERVALS.map((interval) => (
                            <label key={interval}>
                                <input
                                    type="checkbox"
                                    checked={account.supportedBetIntervals?.includes(
                                        interval
                                    )}
                                    onChange={(e) =>
                                        setAccount({
                                            ...account,
                                            supportedBetIntervals: e.target
                                                .checked
                                                ? [
                                                      ...account.supportedBetIntervals,
                                                      interval,
                                                  ]
                                                : account.supportedBetIntervals.filter(
                                                      (t) => t !== interval
                                                  ),
                                        })
                                    }
                                />
                                <span>
                                    {interval === 'Segment'
                                        ? 'Quarter/Period'
                                        : interval}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={`${styles.flex_container} ${styles.checkbox}`}>
                    <label>Bet Leagues</label>
                    <div className={styles.checkbox_container}>
                        {SUPPORTED_BET_LEAGUES.map((league) => (
                            <label key={league}>
                                <input
                                    type="checkbox"
                                    checked={account.supportedBetLeagues?.includes(
                                        league
                                    )}
                                    onChange={(e) =>
                                        setAccount({
                                            ...account,
                                            supportedBetLeagues: e.target
                                                .checked
                                                ? [
                                                      ...account.supportedBetLeagues,
                                                      league,
                                                  ]
                                                : account.supportedBetLeagues.filter(
                                                      (t) => t !== league
                                                  ),
                                        })
                                    }
                                />
                                <span>{league}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={styles.checkbox}>
                    <label>
                        <input
                            type="checkbox"
                            checked={account.isScraperAccount}
                            onChange={() =>
                                setAccount({
                                    ...account,
                                    isScraperAccount: !account.isScraperAccount,
                                })
                            }
                        />
                        <span>Scraper Account</span>
                    </label>
                </div>
                <div className={styles.flex_container}>
                    {error && <span className={styles.error}>{error}</span>}
                    <button
                        onClick={onSubmit}
                        disabled={
                            isLoading ||
                            (!isExistingAccount &&
                                (!account.website ||
                                    !account.username ||
                                    !account.password ||
                                    !account.accountProviders ||
                                    account.accountProviders.length === 0 ||
                                    !account.autoBetAmount))
                        }
                    >
                        {isLoading ? '...' : isExistingAccount ? 'Save' : 'Add'}
                    </button>
                </div>
            </div>
        </>
    )
}
