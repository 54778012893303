import { useContext, useEffect, useMemo, useState } from 'react'
import { GameIF } from '../utils/types'
import { Odds } from './Odds'
import styles from './Game.module.css'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { formatTeam } from '../utils/bets'
import { Context } from '../utils/context'
import { getOdds } from '../services/api'
import Loader from 'react-spinners/BounceLoader'

interface PropsIF {
    games: GameIF[]
}

export const Game = (props: PropsIF) => {
    const { games } = props
    const { webSocket, odds, setOdds, highlightOddsIds } = useContext(Context)
    const [isOpen, setIsOpen] = useState(false)
    const [isLoaded, setIsLoaded] = useState(true)

    const gameOdds = useMemo(
        () => odds.filter((o) => games.some((game) => game.id === o.gameId)),
        [odds, games]
    )
    useEffect(() => {
        ;(async () => {
            if (isOpen) {
                const odds = await getOdds(games.map((game) => game.id))
                if (odds) {
                    setOdds((prev) =>
                        prev
                            .filter(
                                (o) =>
                                    !games.some((game) => game.id === o.gameId)
                            )
                            .concat(
                                odds.filter(
                                    (odd) =>
                                        odd.line &&
                                        games.some(
                                            (game) => game.id === odd.gameId
                                        )
                                )
                            )
                    )
                }
                setIsLoaded(true)
            } else {
                setOdds((prev) =>
                    prev.filter(
                        (o) => !games.some((game) => game.id === o.gameId)
                    )
                )
                setIsLoaded(false)
            }
        })()
    }, [games, isOpen, setOdds])

    useEffect(() => {
        if (webSocket)
            if (isOpen) {
                webSocket.emit(
                    'join',
                    games.map((game) => game.id)
                )
            }
        return () => {
            if (webSocket) {
                webSocket.emit(
                    'leave',
                    games.map((game) => game.id)
                )
            }
        }
    }, [webSocket, games, isOpen])

    return (
        <div className={styles.game}>
            <div className={styles.button} onClick={() => setIsOpen(!isOpen)}>
                {formatTeam(games[0].awayTeam, games[0].homeTeam)}
                {isOpen ? (
                    <BiChevronUp size={20} />
                ) : (
                    <BiChevronDown size={20} />
                )}
            </div>

            {isOpen &&
                (gameOdds.length > 0 ? (
                    <Odds
                        odds={gameOdds}
                        games={games}
                        highlighted={highlightOddsIds}
                    />
                ) : !isLoaded ? (
                    <div className={styles.loading}>
                        <Loader
                            loading={!isLoaded}
                            size={48}
                            color={'var(--dark1)'}
                        />
                    </div>
                ) : (
                    <div className={styles.padding}>
                        <p style={{ color: 'var(--warn)' }}>
                            No odds available. Please check again later.
                        </p>
                    </div>
                ))}
        </div>
    )
}
