import { downloadReport } from '../services/api'
import styles from '../views/Reports.module.css'
import { getAccountName } from './bets'
import { nowInEST, ESTToUTC, UTCToEST } from './timezone'
import { AccountIF, ReportFilterIF } from './types'

export enum ReportType {
    DAILY = 'Daily',
    LAST_WEEK = 'Last Week',
    THIS_WEEK = 'This Week',
    MONTHLY = 'Monthly',
    CUSTOM = 'Custom',
}

export enum SummaryType {
    ACCOUNT = 'Account',
    SOURCE = 'Source',
}

export const BetStatuses = {
    Completed: {
        label: 'Placed',
        order: 1,
        style: {
            button: styles.button_status,
            selected: styles.selected_status,
        },
    },
    Pending: {
        label: 'Pending',
        order: 2,
        style: {
            button: styles.button_status,
            selected: styles.selected_status,
        },
    },
    Unprocessed: {
        label: 'Unprocessed',
        order: 3,
        style: {
            button: styles.button_status,
            selected: styles.selected_status,
        },
    },
    Error: {
        label: 'Failed',
        order: 4,
        style: {
            button: styles.button_status,
            selected: styles.selected_status,
        },
    },
}

export const BetOutcomes = {
    Won: {
        label: 'Won',
        order: 1,
        style: {
            button: styles.button_success,
            selected: styles.selected_success,
        },
    },
    Pending: {
        label: 'In Progress',
        order: 2,
        style: {
            button: styles.button_warn,
            selected: styles.selected_warn,
        },
    },
    Void: {
        label: 'Void',
        order: 3,
        style: {
            button: styles.button_neutral,
            selected: styles.selected_neutral,
        },
    },
    Push: {
        label: 'Push',
        order: 4,
        style: {
            button: styles.button_neutral,
            selected: styles.selected_neutral,
        },
    },
    Lost: {
        label: 'Lost',
        order: 5,
        style: {
            button: styles.button_danger,
            selected: styles.selected_danger,
        },
    },
}

export const BetProviders = {
    plive: {
        label: 'plive',
        order: 1,
        style: {
            button: styles.button_plive,
            selected: styles.selected_plive,
        },
    },
    pezlive: {
        label: 'pezlive',
        order: 2,
        style: {
            button: styles.button_pezlive,
            selected: styles.selected_pezlive,
        },
    },
    dynamic: {
        label: 'dynamic',
        order: 3,
        style: {
            button: styles.button_dynamic,
            selected: styles.selected_dynamic,
        },
    },
    ultra: {
        label: 'ultra',
        order: 4,
        style: {
            button: styles.button_ultra,
            selected: styles.selected_ultra,
        },
    },
    metallic: {
        label: 'metallic',
        order: 5,
        style: {
            button: styles.button_metallic,
            selected: styles.selected_metallic,
        },
    },
    cris: {
        label: 'cris',
        order: 6,
        style: {
            button: styles.button_cris,
            selected: styles.selected_cris,
        },
    },
    ghetto: {
        label: 'ghetto',
        order: 7,
        style: {
            button: styles.button_ghetto,
            selected: styles.selected_ghetto,
        },
    },
}

export interface ReportPeriod {
    type: ReportType
    start: Date
    end: Date
}

export const setPeriodRange = (type: ReportType, curr?: ReportPeriod) => {
    let start, end

    const now = nowInEST

    switch (type) {
        case ReportType.DAILY:
            start = ESTToUTC(
                new Date(now.getFullYear(), now.getMonth(), now.getDate())
            )
            end = ESTToUTC(
                new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
            )
            break
        case ReportType.LAST_WEEK:
            // Monday
            start = ESTToUTC(
                new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate() -
                        now.getDay() +
                        (now.getDay() === 0 ? -6 : 1) -
                        7
                )
            )
            end = new Date(start)
            end.setDate(end.getDate() + 7)
            break
        case ReportType.THIS_WEEK:
            // Monday
            start = ESTToUTC(
                new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1)
                )
            )
            end = new Date(start)
            end.setDate(end.getDate() + 7)
            break
        case ReportType.MONTHLY:
            start = ESTToUTC(new Date(now.getFullYear(), now.getMonth(), 1)) // Set to the first of the month
            end = ESTToUTC(
                new Date(start.getFullYear(), start.getMonth() + 1, 1)
            )
            break
        default:
            start = curr?.start || now
            end = curr?.end || now
    }
    return {
        type,
        start,
        end,
    }
}
export const capitalize = (val: string) =>
    val && val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()

export const getAccountDisplayName = (account: AccountIF) =>
    `${getAccountName(account.website)} | ${account.username}`
export const getHeader = (period: ReportPeriod) => {
    switch (period.type) {
        case ReportType.DAILY:
            return `for ${UTCToEST(period.start)}`
        case ReportType.LAST_WEEK:
        case ReportType.THIS_WEEK:
            return `for week of ${UTCToEST(period.start)}`
        case ReportType.MONTHLY:
            return `for ${new Date(UTCToEST(period.start)).toLocaleString(
                'default',
                {
                    month: 'long',
                }
            )}`
        case ReportType.CUSTOM:
            return `from ${UTCToEST(period.start)} to ${UTCToEST(
                new Date(period.end.getTime() - 24 * 60 * 60 * 1000)
            )}`
        default:
            return ''
    }
}

export const getFiltersQuery = (filters: ReportFilterIF) => {
    const { provider, outcome, status, user, source, account } = filters
    return `${provider.length > 0 ? `&provider=${provider.join(',')}` : ''}${
        outcome.length > 0 ? `&outcome=${outcome.join(',')}` : ''
    }${
        status.length > 0
            ? `&status=${status.map((s) => s.toUpperCase()).join(',')}`
            : ''
    }${user.length > 0 ? `&user=${user.join(',')}` : ''}${
        source.length > 0 ? `&source=${source.join(',')}` : ''
    }${account.length > 0 ? `&account=${account.join(',')}` : ''}`
}

export const downloadCSV = async (
    name: string,
    startDate: Date,
    endDate: Date,
    includesBets: boolean,
    selectedFilters: ReportFilterIF
) => {
    const csvData = await downloadReport(
        startDate,
        endDate,
        includesBets,
        selectedFilters
    )
    if (!csvData) return
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `Betnet Report - ${name}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
