import { useContext } from 'react'
import { Context } from '../utils/context'
import { UserRoles } from '../utils/types'
import { MainBettor } from './MainBet'
import { Reports } from './Reports'
import Loader from 'react-spinners/BounceLoader'
import styles from './MainBet.module.css'

export const Main = () => {
    const { loggedInUser } = useContext(Context)

    if (loggedInUser?.role === UserRoles.BETTOR) {
        return <MainBettor />
    } else if (loggedInUser?.role === UserRoles.ADMIN) {
        return <Reports />
    } else {
        return (
            <div className={styles.loading}>
                <Loader loading={true} size={48} color={'var(--dark1)'} />
            </div>
        )
    }
}
