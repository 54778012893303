import { useEffect, useState } from 'react'
import { formatNumber } from '../utils/bets'
import { ReportOverviewIF } from '../utils/types'
import styles from './Reports.module.css'
import { getReportSummary } from '../services/api'

export const ReportSummary = (props: {
    type: 'Account' | 'Source'
    startDate: Date
    endDate: Date
    source?: string
    onClick: (val: string) => void
}) => {
    const { startDate, endDate, type, source, onClick } = props

    useEffect(() => {
        ;(async () => {
            const data = await getReportSummary(
                startDate,
                endDate,
                type,
                source
            )
            data &&
                setGroups(
                    data
                        .map((group) => ({
                            [type === 'Account'
                                ? group.account ?? ''
                                : group.source ?? '']: group,
                        }))
                        .reduce((acc, val) => ({ ...acc, ...val }), {})
                )
        })()
    }, [startDate, endDate, type, source])
    const [groups, setGroups] = useState<{ [key: string]: ReportOverviewIF }>(
        {}
    )

    return (
        <>
            {Object.entries(groups).map(([label, overview]) => (
                <div
                    key={label}
                    className={styles.summary_card}
                    onClick={() => onClick(label)}
                >
                    <div className={styles.bet_group_info}>
                        <p>{label}</p>
                        <p className={styles.bet_account_info}>
                            <span>
                                <span style={{ color: 'var(--success)' }}>{`${
                                    overview.won || 0
                                }`}</span>
                                {' | '}
                                <span
                                    style={{ color: 'var(--success)' }}
                                >{`$${overview.amountWon.toFixed(2)}`}</span>
                            </span>
                            <span>
                                <span style={{ color: 'gray' }}>{`${
                                    overview.void || 0
                                }`}</span>
                                {' | '}
                                <span
                                    style={{ color: 'gray' }}
                                >{`$${overview.amountVoided?.toFixed(
                                    2
                                )}`}</span>
                            </span>
                            <span>
                                <span style={{ color: 'var(--danger)' }}>{`${
                                    overview.lost || 0
                                }`}</span>
                                {' | '}
                                <span
                                    style={{ color: 'var(--danger)' }}
                                >{`$${overview.amountLost.toFixed(2)}`}</span>
                            </span>
                        </p>
                        <p>
                            <span
                                style={{
                                    color:
                                        overview.amountWon ===
                                        overview.amountLost
                                            ? 'var(--text1)'
                                            : overview.amountWon >
                                              overview.amountLost
                                            ? 'var(--success)'
                                            : 'var(--danger)',
                                }}
                            >
                                ${overview.net.toFixed(2)}{' '}
                            </span>
                            {` | ${
                                type === 'Account'
                                    ? `${formatNumber(
                                          (overview.liability || 0) * 100
                                      )}% | `
                                    : ''
                            }`}{' '}
                            <span
                                style={{
                                    color:
                                        overview.amountWon ===
                                        overview.amountLost
                                            ? 'var(--text1)'
                                            : overview.amountWon >
                                              overview.amountLost
                                            ? 'var(--success)'
                                            : 'var(--danger)',
                                }}
                            >
                                ${overview.netLiable.toFixed(2)}
                            </span>
                        </p>
                    </div>
                </div>
            ))}
        </>
    )
}
