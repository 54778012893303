import { useState } from 'react'
import { Modal } from '../components/Modal'
import { AccountIF, AccountScraperIF } from '../utils/types'
import styles from './Accounts.module.css'
import { FiDownload } from 'react-icons/fi'
import { downloadCSV, propertiesMap } from '../utils/accounts'

const propertiesToOmit = ['id', 'password', 'accountProviders', 'activity']

export const AccountDownloadModal = (props: {
    accounts: AccountScraperIF[]
    onClose: () => void
}) => {
    const { accounts, onClose } = props
    const options = (Object.keys(accounts[0]) as (keyof AccountIF)[]).filter(
        (x) => !propertiesToOmit.includes(x)
    )
    const [selectedOptions, setSelectedOptions] = useState<string[]>([
        'source',
        'username',
        'proxyCity',
        'autoBetAmount',
        'winTotal',
    ])

    const onClick = (option: string) => {
        if (!selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.concat([option]))
        } else {
            setSelectedOptions(selectedOptions.filter((x) => x !== option))
        }
    }

    const onDownload = () => {
        const toInclude = options
            .filter((x) => selectedOptions.includes(x))
            .map((x) => x.toString())
        const rows = [
            toInclude.map((x) => propertiesMap[x as keyof AccountScraperIF]),
        ]
        accounts.forEach((account) => {
            const row: string[] = []
            toInclude.forEach((option) => {
                const data = account[option as keyof AccountScraperIF]
                row.push(data ? `"${data.toString()}"` : '')
            })
            rows.push(row)
        })
        downloadCSV('Betnet Accounts', rows)
    }

    return (
        <Modal onClose={onClose}>
            <div className={styles.modal}>
                <header>Download Accounts {`(${accounts.length})`}</header>
                <form>
                    <div className={styles.filter_checks}>
                        {options.map((option) => (
                            <div key={option}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions.includes(
                                            option
                                        )}
                                        onChange={() => onClick(option)}
                                    />
                                    <span>{propertiesMap[option]} </span>
                                </label>
                            </div>
                        ))}
                    </div>
                    <button
                        type="button"
                        className={styles.download}
                        onClick={() => onDownload()}
                    >
                        <FiDownload />
                        Download Accounts
                    </button>
                </form>
            </div>
        </Modal>
    )
}
