import { useContext } from 'react'
import { IoMdClose } from 'react-icons/io'
import { Context } from '../utils/context'
import { ACCOUNT_STATUS, DropletStatus } from '../utils/types'
import styles from './ScraperNotification.module.css'
import { FaCheck } from 'react-icons/fa'

export const ScraperNotification = () => {
    const {
        games,
        accounts,
        showStatusNotification,
        setShowStatusNotification,
    } = useContext(Context)

    const intializedGames = games.filter(
        (g) => g.dropletStatus === DropletStatus.ACTIVE
    )

    const activeGames = games.filter((g) => g.dropletStatus)

    const initializedAccounts = accounts.filter((a) =>
        a.accountProviders.find((g) => g.dropletStatus === DropletStatus.ACTIVE)
    )
    const activeAccounts = accounts.filter(
        (a) => a.status === ACCOUNT_STATUS.ACTIVE
    )

    return (
        <div className={styles.notification_container}>
            {showStatusNotification.game && (
                <div className={styles.notification}>
                    {games.length === 0 ? (
                        <p>Initializing scrapers...</p>
                    ) : intializedGames.length !== activeGames.length ? (
                        <>
                            <p>
                                {intializedGames.length} of {activeGames.length}{' '}
                                scrapers initialized
                            </p>
                            <progress
                                style={{ color: 'var(--accent1)' }}
                                value={intializedGames.length}
                                max={activeGames.length}
                            />
                            <IoMdClose
                                className={styles.close}
                                size={18}
                                onMouseDown={() =>
                                    setShowStatusNotification((prev) => ({
                                        ...prev,
                                        game: false,
                                    }))
                                }
                            />
                        </>
                    ) : (
                        <>
                            <p>Ready to scrape!</p>
                            <FaCheck className={styles.check} size={18} />
                        </>
                    )}
                </div>
            )}
            {showStatusNotification.account && (
                <div className={styles.notification}>
                    {accounts.length === 0 ? (
                        <p>Initializing bettors...</p>
                    ) : initializedAccounts.length !== activeAccounts.length ? (
                        <>
                            <p>
                                {initializedAccounts.length} of{' '}
                                {activeAccounts.length} bettors initialized
                            </p>
                            <progress
                                style={{ color: 'var(--accent1)' }}
                                value={initializedAccounts.length}
                                max={activeAccounts.length}
                            />
                            <IoMdClose
                                className={styles.close}
                                size={18}
                                onMouseDown={() =>
                                    setShowStatusNotification((prev) => ({
                                        ...prev,
                                        account: false,
                                    }))
                                }
                            />
                        </>
                    ) : (
                        <>
                            <p>Ready to bet!</p>
                            <FaCheck className={styles.check} size={18} />
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
