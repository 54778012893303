import { Dispatch, SetStateAction, useCallback } from 'react'
import {
    BetOutcomes,
    BetProviders,
    BetStatuses,
    SummaryType,
    capitalize,
} from '../utils/reports'
import { ReportFilterIF } from '../utils/types'
import styles from './Reports.module.css'
import { Dropdown } from '../components/Dropdown'

export const ReportFilters = (props: {
    filters: ReportFilterIF
    selectedFilters: ReportFilterIF
    setSelectedFilters: Dispatch<SetStateAction<ReportFilterIF>>
    showSummary?: SummaryType | undefined
    setShowSummary?: Dispatch<SetStateAction<SummaryType | undefined>>
    dropdown?: boolean
}) => {
    const {
        filters,
        selectedFilters,
        showSummary,
        setShowSummary,
        setSelectedFilters,
        dropdown,
    } = props

    const onFilterButtonClick = useCallback(
        (
            label: string,
            type:
                | 'status'
                | 'account'
                | 'outcome'
                | 'user'
                | 'provider'
                | 'source'
        ) => {
            switch (type) {
                case 'status':
                    if (selectedFilters.status.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            status: selectedFilters.status.filter(
                                (s) => s !== label
                            ),
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            status: [...selectedFilters.status, label],
                        })
                    break
                case 'account':
                    if (selectedFilters.account[0] === label)
                        setSelectedFilters({
                            ...selectedFilters,
                            account: [],
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            account: [label],
                        })
                    break
                case 'outcome':
                    if (selectedFilters.outcome.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            outcome: selectedFilters.outcome.filter(
                                (o) => o !== label
                            ),
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            outcome: [...selectedFilters.outcome, label],
                        })
                    break
                case 'user':
                    if (selectedFilters.user.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            user: selectedFilters.user.filter(
                                (u) => u !== label
                            ),
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            user: [...selectedFilters.user, label],
                        })
                    break
                case 'provider':
                    if (selectedFilters.provider.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            provider: selectedFilters.provider.filter(
                                (p) => p !== label
                            ),
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            provider: [...selectedFilters.provider, label],
                        })
                    break
                case 'source':
                    if (selectedFilters.source.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            source: [],
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            source: [label],
                        })
            }
        },
        [selectedFilters, setSelectedFilters]
    )

    const onFilterDropdownSelect = useCallback(
        (label: string | undefined, type: 'Account' | 'Source') => {
            if (type === 'Source') {
                if (label) {
                    if (label !== 'Summary') {
                        onFilterButtonClick(label, 'source')
                        showSummary === SummaryType.SOURCE &&
                            setShowSummary &&
                            setShowSummary(undefined)
                    } else {
                        setShowSummary && setShowSummary(SummaryType.SOURCE)
                        setSelectedFilters({
                            provider: [],
                            outcome: [],
                            user: [],
                            status: [],
                            account: [],
                            source: [],
                        })
                    }
                } else if (showSummary === SummaryType.SOURCE) {
                    setShowSummary && setShowSummary(undefined)
                } else {
                    setSelectedFilters({
                        ...selectedFilters,
                        source: [],
                    })
                }
            }
            if (type === 'Account') {
                if (label) {
                    if (label !== 'Summary') {
                        onFilterButtonClick(label, 'account')
                        setShowSummary && setShowSummary(undefined)
                    } else {
                        setShowSummary && setShowSummary(SummaryType.ACCOUNT)
                        setSelectedFilters((prev) => ({
                            provider: [],
                            outcome: [],
                            user: [],
                            status: [],
                            account: [],
                            source: prev.source,
                        }))
                    }
                } else if (showSummary === SummaryType.ACCOUNT) {
                    setShowSummary && setShowSummary(undefined)
                } else {
                    setSelectedFilters({
                        ...selectedFilters,
                        account: [],
                    })
                }
            }
        },
        [
            selectedFilters,
            setSelectedFilters,
            showSummary,
            setShowSummary,
            onFilterButtonClick,
        ]
    )

    const FilterButton = useCallback(
        (props: {
            label: string
            type:
                | 'status'
                | 'outcome'
                | 'account'
                | 'user'
                | 'provider'
                | 'source'
        }) => {
            const { label, type } = props
            const filter =
                type === 'status'
                    ? BetStatuses[label as keyof typeof BetStatuses]
                    : type === 'outcome'
                    ? BetOutcomes[label as keyof typeof BetOutcomes]
                    : type === 'provider'
                    ? BetProviders[label as keyof typeof BetProviders]
                    : undefined

            return (
                <div
                    key={label}
                    className={`${styles.button} ${
                        filter
                            ? filter.style.button
                            : type === 'user'
                            ? styles.button_user
                            : type === 'source'
                            ? styles.button_source
                            : undefined
                    } ${
                        type === 'status'
                            ? selectedFilters.status.includes(label) &&
                              filter &&
                              filter.style.selected
                            : type === 'outcome'
                            ? selectedFilters.outcome.includes(label) &&
                              filter &&
                              filter.style.selected
                            : type === 'provider'
                            ? selectedFilters.provider.includes(label) &&
                              filter &&
                              filter.style.selected
                            : type === 'user'
                            ? selectedFilters.user.includes(label) &&
                              styles.selected_user
                            : type === 'source'
                            ? selectedFilters.source.includes(label) &&
                              styles.selected_source
                            : undefined
                    }`}
                    onClick={() => onFilterButtonClick(label, type)}
                >
                    {filter?.label ?? label}
                </div>
            )
        },
        [onFilterButtonClick, selectedFilters]
    )

    if (dropdown)
        return (
            <div className={styles.dropdown_container}>
                <div className={styles.dropdown}>
                    <Dropdown
                        options={
                            setShowSummary &&
                            showSummary !== SummaryType.ACCOUNT
                                ? ['Summary', ...filters.source]
                                : filters.source
                        }
                        onSelect={(source) =>
                            onFilterDropdownSelect(source, 'Source')
                        }
                        value={
                            showSummary === SummaryType.SOURCE
                                ? 'Summary'
                                : selectedFilters.source[0]
                        }
                        placeholder="Source"
                        search
                        clear
                    />
                </div>
                {(!showSummary || showSummary === SummaryType.ACCOUNT) && (
                    <div className={styles.dropdown}>
                        <Dropdown
                            options={
                                setShowSummary
                                    ? ['Summary', ...filters.account]
                                    : filters.account
                            }
                            onSelect={(account) =>
                                onFilterDropdownSelect(account, 'Account')
                            }
                            value={
                                showSummary === SummaryType.ACCOUNT
                                    ? 'Summary'
                                    : selectedFilters.account[0]
                            }
                            placeholder="Account"
                            search
                            clear
                        />
                    </div>
                )}
            </div>
        )

    return (
        <div className={styles.filters}>
            {
                <>
                    {filters.provider.length > 0 && (
                        <div className={styles.filter_button_container}>
                            {filters.provider
                                .filter((p) =>
                                    Object.keys(BetProviders).includes(p)
                                )
                                .sort((a, b) => {
                                    const valA =
                                        BetProviders[
                                            a as keyof typeof BetProviders
                                        ]?.order ?? null
                                    const valB =
                                        BetProviders[
                                            b as keyof typeof BetProviders
                                        ]?.order ?? null

                                    if (
                                        valA !== null &&
                                        valB !== null &&
                                        valA !== valB
                                    ) {
                                        return valA - valB
                                    }
                                    return a.localeCompare(b)
                                })
                                .map((provider) => (
                                    <FilterButton
                                        key={provider}
                                        label={provider}
                                        type="provider"
                                    />
                                ))}
                        </div>
                    )}

                    {filters.outcome.filter((o) => !!o).length > 0 && (
                        <div className={styles.filter_button_container}>
                            {filters.outcome
                                .filter((o) => !!o)
                                .sort((a, b) => {
                                    const valA =
                                        BetOutcomes[
                                            a as keyof typeof BetOutcomes
                                        ]?.order ?? null
                                    const valB =
                                        BetOutcomes[
                                            b as keyof typeof BetOutcomes
                                        ]?.order ?? null

                                    if (
                                        valA !== null &&
                                        valB !== null &&
                                        valA !== valB
                                    ) {
                                        return valA - valB
                                    }
                                    return a.localeCompare(b)
                                })
                                .map((outcome) => (
                                    <FilterButton
                                        key={outcome}
                                        label={outcome}
                                        type="outcome"
                                    />
                                ))}
                        </div>
                    )}

                    {filters.status.length > 0 && (
                        <div className={styles.filter_button_container}>
                            {filters.status
                                .sort((a, b) => {
                                    const valA =
                                        BetStatuses[
                                            a as keyof typeof BetStatuses
                                        ]?.order ?? null
                                    const valB =
                                        BetStatuses[
                                            b as keyof typeof BetStatuses
                                        ]?.order ?? null

                                    if (
                                        valA !== null &&
                                        valB !== null &&
                                        valA !== valB
                                    ) {
                                        return valA - valB
                                    }
                                    return a.localeCompare(b)
                                })
                                .map((status) => (
                                    <FilterButton
                                        key={status}
                                        label={capitalize(status)}
                                        type="status"
                                    />
                                ))}
                        </div>
                    )}

                    {filters.user.length > 0 && (
                        <div className={styles.filter_button_container}>
                            {filters.user.map((user) => (
                                <FilterButton
                                    key={user}
                                    label={user}
                                    type="user"
                                />
                            ))}
                        </div>
                    )}
                </>
            }
        </div>
    )
}
