import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { GameIF, OddsIF } from '../utils/types'
import { BetModal } from './BetModal'
import styles from './Odds.module.css'
import _ from 'lodash'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'

interface PropsIF {
    odds: OddsIF[]
    games: GameIF[]
    highlighted: number[]
}

export const Odds = (props: PropsIF) => {
    const { odds, games, highlighted } = props

    const gamesDict = useMemo(
        () =>
            games.reduce((acc: Record<string, GameIF>, game) => {
                acc[game.id] = game
                return acc
            }, {}),
        [games]
    )
    const filters = useMemo(
        () =>
            odds.reduce(
                (
                    acc: {
                        providers: string[]
                        categories: string[]
                        groups: string[]
                    },
                    odd
                ) => {
                    if (
                        !acc.providers.includes(
                            gamesDict[odd.gameId].provider.name
                        )
                    ) {
                        acc.providers.push(gamesDict[odd.gameId].provider.name)
                    }

                    if (!acc.categories.includes(odd.category)) {
                        acc.categories.push(odd.category)
                    }
                    if (!acc.groups.includes(odd.group)) {
                        acc.groups.push(odd.group)
                    }
                    return acc
                },
                { providers: [], categories: [], groups: [] }
            ),
        [odds, gamesDict]
    )

    const [selectedFilters, setSelectedFilters] = useState<{
        providers: string[]
        categories: string[]
        groups: string[]
    }>({ providers: [], categories: [], groups: [] })

    const [selectedOdds, setSelectedOdds] = useState<OddsIF | undefined>()
    const [filteredOdds, setFilteredOdds] = useState<{
        [category: string]: { [group: string]: OddsIF[] }
    }>({})
    const [showAllOdds, setShowAllOdds] = useState<string[]>([])

    useEffect(() => {
        setFilteredOdds(
            odds
                .filter(
                    (o) =>
                        (selectedFilters.providers.length === 0 ||
                            selectedFilters.providers.includes(
                                gamesDict[o.gameId].provider.name
                            )) &&
                        (selectedFilters.categories.length === 0 ||
                            selectedFilters.categories.includes(o.category)) &&
                        (selectedFilters.groups.length === 0 ||
                            selectedFilters.groups.includes(o.group))
                )
                .reduce(
                    (
                        acc: {
                            [category: string]: { [group: string]: OddsIF[] }
                        },
                        odds
                    ) => {
                        acc[odds.category] = acc[odds.category] || {}
                        acc[odds.category][odds.group] = acc[odds.category][
                            odds.group
                        ]
                            ? [...acc[odds.category][odds.group], odds]
                            : [odds]
                        return acc
                    },
                    {}
                )
        )
    }, [odds, selectedFilters, gamesDict])

    // if the filter no longer exists, remove it from selected
    const cleanFilters = useMemo(() => {
        if (Object.values(selectedFilters).every((array) => array.length === 0))
            return undefined
        const newFilters = { ...selectedFilters }
        newFilters.providers = selectedFilters.providers.filter((provider) =>
            filters.providers.includes(provider)
        )
        newFilters.categories = selectedFilters.categories.filter((category) =>
            filters.categories.includes(category)
        )
        newFilters.groups = selectedFilters.groups.filter((group) =>
            filters.groups.includes(group)
        )

        return newFilters
    }, [filters, selectedFilters])
    useEffect(() => {
        if (cleanFilters && !_.isEqual(selectedFilters, cleanFilters)) {
            setSelectedFilters(cleanFilters)
        }
    }, [cleanFilters, selectedFilters])

    const onFilterButtonClick = useCallback(
        (label: string, type: 'provider' | 'category' | 'group') => {
            switch (type) {
                case 'provider':
                    if (selectedFilters.providers.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            providers: selectedFilters.providers.filter(
                                (p) => p !== label
                            ),
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            providers: [...selectedFilters.providers, label],
                        })
                    break
                case 'category':
                    if (selectedFilters.categories.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            categories: selectedFilters.categories.filter(
                                (c) => c !== label
                            ),
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            categories: [...selectedFilters.categories, label],
                        })
                    break
                case 'group':
                    if (selectedFilters.groups.includes(label))
                        setSelectedFilters({
                            ...selectedFilters,
                            groups: selectedFilters.groups.filter(
                                (g) => g !== label
                            ),
                        })
                    else
                        setSelectedFilters({
                            ...selectedFilters,
                            groups: [...selectedFilters.groups, label],
                        })
            }
        },
        [selectedFilters]
    )

    const FilterButton = useCallback(
        (props: { label: string; type: 'provider' | 'category' | 'group' }) => {
            const { label, type } = props

            return (
                <div
                    className={`${
                        type === 'provider'
                            ? styles.button_providers
                            : type === 'category'
                            ? styles.button_categories
                            : styles.button_groups
                    } ${
                        type === 'category'
                            ? selectedFilters.categories.includes(label) &&
                              styles.selected_categories
                            : selectedFilters.groups.includes(label) &&
                              styles.selected_groups
                    }`}
                    style={{
                        color:
                            type === 'provider'
                                ? `var(--text-${label})`
                                : undefined,
                        border:
                            type === 'provider'
                                ? `1px solid var(--accent-${label})`
                                : undefined,
                        backgroundColor: selectedFilters.providers.includes(
                            label
                        )
                            ? `var(--accent-${label})`
                            : undefined,
                    }}
                    onClick={() => onFilterButtonClick(label, type)}
                >
                    {label}
                </div>
            )
        },
        [onFilterButtonClick, selectedFilters]
    )

    const getOddValue = (odd: string): number | null => {
        const parts = odd.split(' ')
        const lastPart = parts[parts.length - 1]

        if (lastPart === 'PS' || lastPart === 'PK') return 0
        if (isNaN(parseFloat(lastPart))) {
            return null
        } else {
            return parseFloat(lastPart)
        }
    }

    const showOdds = useCallback(
        (odds: OddsIF[]) => {
            const sortAndDisplayOdds = (odds: OddsIF[], reverse = false) =>
                odds
                    .map((odd) => odd)
                    .sort((a, b) => {
                        if (a.description === 'Draw') return 1
                        if (b.description === 'Draw') return -1

                        const valA = getOddValue(a.description)
                        const valB = getOddValue(b.description)

                        if (valA !== null && valB !== null && valA !== valB) {
                            // reverse for second column of spread
                            return (valA - valB) * (reverse ? -1 : 1)
                        }
                        return a.description.localeCompare(b.description)
                    })
                    .map((o) => (
                        <div
                            key={[
                                o.gameId,
                                o.metadata.category,
                                o.metadata.group,
                                o.metadata.description,
                            ].join('-')}
                            className={styles.descriptions}
                            onClick={() => setSelectedOdds(o)}
                        >
                            <p
                                style={{
                                    color: `var(--text-${
                                        gamesDict[o.gameId].provider.name
                                    }`,
                                }}
                            >
                                {o.description}
                            </p>
                            <p
                                className={
                                    highlighted.includes(o.id)
                                        ? styles.highlight
                                        : ''
                                }
                            >
                                {o.line > 0 && '+'}
                                {o.line}
                            </p>
                        </div>
                    ))

            const getDescriptionText = (str: string) =>
                str
                    .replace(/\b(pk|ps)\b/, '0')
                    .split(/([0-9+-])/)[0]
                    .trim()

            const oddsDict: { [desc: string]: OddsIF[] } = {}

            odds.forEach((o) => {
                const text = getDescriptionText(o.description.toLowerCase())
                oddsDict[text] = oddsDict[text] ? [...oddsDict[text], o] : [o]
            })
            if (Object.values(oddsDict).find((o) => o.length > 1))
                return (
                    <>
                        {Object.keys(oddsDict)
                            .sort((a, b) => a.localeCompare(b))
                            .map((key, i) => (
                                <div className={styles.columns} key={key}>
                                    {sortAndDisplayOdds(
                                        oddsDict[key],
                                        oddsDict[key][0].group ===
                                            'Point Spread' && i === 1
                                    )}
                                </div>
                            ))}
                    </>
                )
            return sortAndDisplayOdds(odds)
        },
        [gamesDict, highlighted]
    )

    const sortBasedOnPatterns = useCallback(
        (array: string[], type: 'provider' | 'category' | 'group') => {
            const patterns =
                type === 'provider'
                    ? [
                          'plive',
                          'pezlive',
                          'dynamic',
                          'ultra',
                          'metallic',
                          'cris',
                      ]
                    : type === 'category'
                    ? [
                          'Game',
                          '^Match',
                          '^Regulation',
                          '(1st|2nd) Half',
                          '(1st|2nd|3rd|4th) Quarter',
                          '(1st|2nd|3rd) Period',
                          '(1st|2nd|3rd|4th|5th|6th|7th|8th|9th) Inning',
                      ]
                    : type === 'group'
                    ? [
                          'Result$',
                          '^Money Line$',
                          '^Puck Line$',
                          '^Point Spread$',
                          '^Total$',
                          '(Period|Regulation) Result',
                          'Point Spread$',
                          'Total$',
                          'Both Team To Score',
                          'Win No Bet$',
                          'Draw No Bet',
                      ]
                    : []
            const regexPatterns = patterns.map((pattern) => new RegExp(pattern))

            return array.sort((a, b) => {
                const aIndex = regexPatterns.findIndex((regex) => regex.test(a))
                const bIndex = regexPatterns.findIndex((regex) => regex.test(b))

                if (aIndex === bIndex) {
                    return a.localeCompare(b)
                }
                // Elements not matching any pattern are placed at the end
                if (aIndex === -1) return 1
                if (bIndex === -1) return -1

                return aIndex - bIndex
            })
        },
        []
    )

    const showGroups = useCallback(
        (groups: { [group: string]: OddsIF[] }) => {
            const distributeGroups = (
                groups: { id: string; count: number }[]
            ) => {
                const column1: string[] = []
                const column2: string[] = []
                let count1 = 0,
                    count2 = 0

                groups.forEach((group) => {
                    if (count1 <= count2) {
                        column1.push(group.id)
                        count1 += group.count
                    } else {
                        column2.push(group.id)
                        count2 += group.count
                    }
                })

                return { column1, column2 }
            }

            const sortedGroupIds = sortBasedOnPatterns(
                Object.keys(groups),
                'group'
            )
            const distributedGroupIds = distributeGroups(
                sortedGroupIds.map((id) => ({ id, count: groups[id].length }))
            )
            const group = (groupId: string) => {
                const showAll =
                    groups[groupId].length > 6 &&
                    groups[groupId].find(
                        (o) =>
                            o.metadata.category.includes('Alternative') ||
                            o.metadata.group.includes('Alternative')
                    )
                        ? showAllOdds.includes(groupId)
                        : undefined
                const getOdds = () => {
                    if (showAll === true || showAll === undefined)
                        return groups[groupId]
                    return groups[groupId]
                        .sort(
                            (a, b) =>
                                Math.abs(100 - a.line) - Math.abs(100 - b.line)
                        )
                        .filter((odds) => {
                            // show only main line for ultra, cris
                            if (
                                gamesDict[odds.gameId].provider.name === 'ultra'
                            )
                                return !odds.metadata.category.includes(
                                    'Alternative'
                                )

                            if (gamesDict[odds.gameId].provider.name === 'cris')
                                return !odds.metadata.group.includes(
                                    'Alternative'
                                )
                            return true
                        })
                }
                return (
                    <Fragment key={groupId}>
                        <div className={styles.groups}>
                            {showAll !== undefined ? (
                                <div
                                    className={styles.group}
                                    onClick={() =>
                                        setShowAllOdds((prev) => {
                                            const newSet = new Set(prev)
                                            showAll
                                                ? newSet.delete(groupId)
                                                : newSet.add(groupId)
                                            return Array.from(newSet)
                                        })
                                    }
                                >
                                    <p>{groupId}</p>
                                    {showAll === undefined ? (
                                        ''
                                    ) : showAll ? (
                                        <BiChevronUp size={20} />
                                    ) : (
                                        <BiChevronDown size={20} />
                                    )}
                                </div>
                            ) : (
                                <p>{groupId}</p>
                            )}
                            <div className={styles.description_container}>
                                {showOdds(getOdds())}
                            </div>
                        </div>
                    </Fragment>
                )
            }
            return (
                <div className={styles.row}>
                    <div className={styles.columns} style={{ flex: 1 }}>
                        {distributedGroupIds.column1.map((id) => group(id))}
                    </div>
                    <div className={styles.columns} style={{ flex: 1 }}>
                        {distributedGroupIds.column2.map((id) => group(id))}
                    </div>
                </div>
            )
        },
        [showOdds, sortBasedOnPatterns, showAllOdds, gamesDict]
    )

    return (
        <div className={styles.odds}>
            <div className={styles.filters}>
                <>
                    {sortBasedOnPatterns(filters.providers, 'provider').map(
                        (provider) => (
                            <FilterButton
                                key={provider}
                                label={provider}
                                type="provider"
                            />
                        )
                    )}
                    <span className={styles.divider} />
                    {sortBasedOnPatterns(filters.categories, 'category').map(
                        (category) => (
                            <FilterButton
                                key={category}
                                label={category}
                                type="category"
                            />
                        )
                    )}
                    <span className={styles.divider} />
                    {sortBasedOnPatterns(filters.groups, 'group').map(
                        (group) => (
                            <FilterButton
                                key={group}
                                label={group}
                                type="group"
                            />
                        )
                    )}
                </>
            </div>
            {sortBasedOnPatterns(Object.keys(filteredOdds), 'category').map(
                (category) => (
                    <div key={category} className={styles.categories}>
                        <p style={{ color: 'var(--text2)' }}>{category}</p>
                        {showGroups(filteredOdds[category])}
                    </div>
                )
            )}
            {selectedOdds && (
                <BetModal
                    odds={odds.find((o) => o.id === selectedOdds.id)}
                    game={gamesDict[selectedOdds.gameId]}
                    highlight={highlighted.includes(selectedOdds.id)}
                    onClose={() => setSelectedOdds(undefined)}
                />
            )}
        </div>
    )
}
