import { useState } from 'react'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'
import { UTCToEST } from '../utils/timezone'
import { BetIF } from '../utils/types'
import styles from './Reports.module.css'
import {
    formatNumber,
    formatTeam,
    getAccountName,
    getOutcomeAmount,
    getRiskAmount,
} from '../utils/bets'

export const ReportBetCard = (props: { bets: BetIF[] }) => {
    const { bets } = props
    const [isOpen, setIsOpen] = useState(false)

    const placedBets = bets.filter((b) => b.status === 'COMPLETED')
    const wonBets = bets.filter((b) => b.outcome === 'Won')
    const lostBets = bets.filter((b) => b.outcome === 'Lost')
    const voidedBets = bets.filter((b) => b.outcome === 'Void')

    // try to use plive as formatted bet
    const formattedBet =
        bets.find((b) => b.odds.game.id.startsWith('plive')) || bets[0]
    return (
        <div className={styles.bet_card}>
            <div
                className={styles.bet_group_info}
                style={{ cursor: bets.length > 1 ? 'pointer' : 'default' }}
                onClick={() => {
                    bets.length > 1 && setIsOpen(!isOpen)
                }}
            >
                <div className={styles.odds_info}>
                    <p>{`${formatTeam(
                        formattedBet.odds.game.awayTeam,
                        formattedBet.odds.game.homeTeam
                    )} | ${formattedBet.odds.category} ${
                        formattedBet.odds.group
                    }${
                        formattedBet.odds.description
                            ? ` | ${formattedBet.odds.description}`
                            : ''
                    }`}</p>
                </div>

                <div className={styles.bet_group_info}>
                    <p>{UTCToEST(new Date(bets[0].createdAt))}</p>
                    {bets.length > 1 ? (
                        isOpen ? (
                            <BiChevronUp
                                className={styles.card_arrow}
                                size={20}
                            />
                        ) : (
                            <BiChevronDown
                                className={styles.card_arrow}
                                size={20}
                            />
                        )
                    ) : null}
                </div>
            </div>
            {isOpen || bets.length === 1 ? (
                bets.map((b) => (
                    <div key={b.id} className={styles.bet_details}>
                        <p>
                            <span
                                style={{
                                    color:
                                        b.provider.name === 'plive'
                                            ? 'var(--text-plive)'
                                            : b.provider.name === 'pezlive'
                                            ? 'var(--text-pezlive)'
                                            : b.provider.name === 'ultra'
                                            ? 'var(--text-ultra)'
                                            : b.provider.name === 'dynamic'
                                            ? 'var(--text-dynamic)'
                                            : b.provider.name === 'metallic'
                                            ? 'var(--text-metallic)'
                                            : b.provider.name === 'cris'
                                            ? 'var(--text-cris)'
                                            : 'var(--text1)',
                                }}
                            >
                                {b.provider.name}
                            </span>
                            {' | '}
                            <a
                                href={b.account.website}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {getAccountName(b.account.website)}
                            </a>
                            {' | '}
                            {b.account.username}
                        </p>
                        <p>
                            {`${b.odds.category} | ${b.odds.group}${
                                b.odds.description
                                    ? ` | ${b.odds.description}`
                                    : ''
                            }
              ${
                  b.realOddsLine
                      ? ` | ${b.realOddsLine > 0 ? '+' : ''}${b.realOddsLine}`
                      : ''
              }`}
                        </p>
                        <p>
                            <span
                                style={{ color: 'var(--text3)' }}
                            >{`${b.user.username}`}</span>
                            {' | '}
                            <span
                                style={{
                                    color: b.transactionCode
                                        ? 'var(--success)'
                                        : b.status === 'UNPROCESSED' ||
                                          b.status === 'ERROR'
                                        ? 'var(--danger)'
                                        : b.status === 'PENDING'
                                        ? 'var(--warn)'
                                        : 'default',
                                }}
                                title={b.errorReason}
                            >{`${
                                b.transactionCode || b.errorCode || b.status
                            }`}</span>
                            {(b.outcome || b.status === 'COMPLETED') && ' | '}
                            <span
                                style={{
                                    color:
                                        b.outcome === 'Won'
                                            ? 'var(--success)'
                                            : b.outcome === 'Lost'
                                            ? 'var(--danger)'
                                            : b.outcome === 'Void'
                                            ? 'var(--text4)'
                                            : 'var(--warn)',
                                }}
                            >{`${
                                b.outcome === 'Won'
                                    ? `Won $${formatNumber(
                                          b.outcomeAmount ?? getOutcomeAmount(b)
                                      )}`
                                    : b.outcome === 'Lost'
                                    ? `Lost $${formatNumber(
                                          b.outcomeAmount ?? getRiskAmount(b)
                                      )}`
                                    : b.outcome === 'Void'
                                    ? `Voided $${formatNumber(
                                          b.riskAmount ?? getRiskAmount(b)
                                      )}`
                                    : b.status === 'Push'
                                    ? `Pushed $${formatNumber(
                                          b.riskAmount ?? getRiskAmount(b)
                                      )}`
                                    : b.status === 'COMPLETED'
                                    ? `At risk $${formatNumber(
                                          b.riskAmount ?? getRiskAmount(b)
                                      )}`
                                    : ''
                            }`}</span>
                        </p>
                    </div>
                ))
            ) : (
                <div className={styles.bet_details}>
                    <p>
                        {`${bets.length} bet${bets.length !== 1 && 's'}:
            ${[
                wonBets.length > 0 ? `${wonBets.length} won` : null,
                placedBets.length -
                    wonBets.length -
                    lostBets.length -
                    voidedBets.length >
                0
                    ? `${
                          placedBets.length -
                          wonBets.length -
                          lostBets.length -
                          voidedBets.length
                      } pending`
                    : null,
                lostBets.length > 0 ? `${lostBets.length} lost` : null,
                voidedBets.length > 0 ? `${voidedBets.length} voided` : null,
                bets.length - placedBets.length > 0
                    ? `${bets.length - placedBets.length} failed`
                    : null,
            ]
                .filter((a) => a)
                .join(', ')}`}
                    </p>
                    <p></p>
                </div>
            )}
        </div>
    )
}
