import styles from './Login.module.css'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../utils/context'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    getUserToken,
    getUserTokenWith2FA,
    resendTwoFactorAuthCode,
} from '../services/api'

export const Login = () => {
    const { loggedInUser, setLoggedInUser } = useContext(Context)
    const navigate = useNavigate()
    const location = useLocation()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const [userId, setUserId] = useState<number | undefined>(undefined)
    const [code, setCode] = useState('')
    const [twoFactorAuthEmail, setTwoFactorAuthEmail] = useState<
        string | undefined
    >(undefined)

    useEffect(() => {
        if (loggedInUser) {
            const from = location.state?.from?.pathname || '/'
            navigate(from, { replace: true })
        }
    }, [loggedInUser, navigate, location])

    const login = async () => {
        setIsLoading(true)
        setError('')
        const token = await getUserToken(username, password)
        if (!token) {
            setError('Invalid credentials.')
        } else {
            if (token.email) {
                setUserId(token.userId)
                setTwoFactorAuthEmail(token.email)
            } else {
                setLoggedInUser({ id: token.userId, role: token.role })
            }
        }
        setIsLoading(false)
    }

    const twoFactorAuth = async (code: string) => {
        if (!userId) {
            setTwoFactorAuthEmail(undefined)
            return
        }
        setIsLoading(true)
        setError('')
        try {
            const token = await getUserTokenWith2FA(userId, code)

            setLoggedInUser({ id: token.userId, role: token.role })
            const from = location.state?.from?.pathname || '/'
            navigate(from, { replace: true })
        } catch (e: any) {
            setError(e.message || 'Invalid code. Please try again.')
        }
        setIsLoading(false)
    }

    const sendTwoFactorAuthCode = async () => {
        if (!userId) {
            setTwoFactorAuthEmail(undefined)
            return
        }
        setIsLoading(true)
        setError('')
        const success = await resendTwoFactorAuthCode(userId)
        if (!success) setError('Failed to send code. Please try again.')
        setIsLoading(false)
    }

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className={styles.login}>
                {twoFactorAuthEmail ? (
                    <>
                        <div className={styles.flex_container}>
                            <label>Code</label>
                            <input
                                type="text"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                autoFocus
                            />
                        </div>
                        <div className={styles.flex_container}>
                            {
                                <p
                                    className={styles.resend_code}
                                    onClick={sendTwoFactorAuthCode}
                                >
                                    Resend Code to {twoFactorAuthEmail}
                                </p>
                            }
                            {error && (
                                <span className={styles.error}>{error}</span>
                            )}
                            <button
                                type="submit"
                                className={styles.full_width}
                                onClick={() => twoFactorAuth(code)}
                                disabled={isLoading}
                            >
                                {!isLoading ? 'Verify' : '...'}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.flex_container}>
                            <label htmlFor="username">Username</label>
                            <input
                                id="username"
                                name="username"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                autoComplete="username"
                                autoFocus
                            />
                        </div>
                        <div className={styles.flex_container}>
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className={styles.flex_container}>
                            {error && (
                                <span className={styles.error}>{error}</span>
                            )}
                            <button
                                type="submit"
                                className={styles.full_width}
                                onClick={login}
                                disabled={isLoading}
                            >
                                {!isLoading ? 'Login' : '...'}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </form>
    )
}
