import { useCallback, useEffect, useState } from 'react'
import { getReport } from '../services/api'
import { BetIF, ReportFilterIF, ReportIF } from '../utils/types'
import styles from './Reports.module.css'
import sidebarStyles from './Sidebar.module.css'
import Loader from 'react-spinners/BounceLoader'
import { ReportBetCard } from './ReportBetCard'
import { Dropdown } from '../components/Dropdown'
import { ReportSummary } from './ReportSummary'
import { ESTToUTC, UTCToEST } from '../utils/timezone'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    ReportType,
    ReportPeriod,
    SummaryType,
    setPeriodRange,
    getHeader,
} from '../utils/reports'
import { ReportOverview } from './ReportOverview'
import { ReportFilters } from './ReportFilters'
import { ReportDownloadModal } from './ReportDownloadModal'

export const Reports = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [period, setPeriod] = useState<ReportPeriod>(
        setPeriodRange(
            (new URLSearchParams(location.search).get(
                'Period'
            ) as ReportType) ?? ReportType.DAILY
        )
    )
    const [report, setReport] = useState<ReportIF>()
    const [selectedFilters, setSelectedFilters] = useState<ReportFilterIF>({
        status: [],
        account: [],
        outcome: [],
        user: [],
        provider: [],
        source: [],
    })
    const [showSummary, setShowSummary] = useState<SummaryType | undefined>(
        (new URLSearchParams(location.search).get('Summary') as SummaryType) ??
            undefined
    )
    const [page, setPage] = useState<number | null>(1)
    const [isLoading, setIsLoading] = useState(false)
    const [showDownloadModal, setShowDownloadModal] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const initialFilters: any = {}
        for (const key of Object.keys(selectedFilters)) {
            const values = queryParams.get(key)
            initialFilters[key] = values ? values.split(',') : []
        }

        setSelectedFilters(initialFilters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const queryParams = new URLSearchParams()
        queryParams.set('Period', period.type)
        showSummary && queryParams.set('Summary', showSummary)

        for (const [key, values] of Object.entries(selectedFilters)) {
            if (values.length > 0) {
                queryParams.set(key, values.join(','))
            }
        }
        if (location.search !== `?${queryParams.toString()}`) {
            setReport(undefined)
            navigate(`${location.pathname}?${queryParams.toString()}`, {
                replace: true,
            })
        }
    }, [period, selectedFilters, showSummary, location, navigate])

    useEffect(() => {
        setPage(1)
    }, [period, selectedFilters])

    useEffect(() => {
        if (!page) return
        const abortController = new AbortController()
        ;(async () => {
            if (period.start && period.end) {
                setIsLoading(true)
                const res = await getReport(
                    period.start,
                    period.end,
                    selectedFilters,
                    page
                )
                if (!abortController.signal.aborted) {
                    if (!res?.bets || res.bets.length === 0) {
                        setPage(null)
                        setIsLoading(false)
                        return
                    }

                    res &&
                        setReport((prev) => ({
                            totals: res.totals,
                            bets: [
                                ...(page > 1 ? prev?.bets ?? [] : []),
                                ...res.bets,
                            ],
                            filters: res.filters ?? prev?.filters ?? null,
                        }))
                }
                setIsLoading(false)
            }
        })()
        return () => abortController.abort()
    }, [period, selectedFilters, page])

    const showBets = useCallback((bets: BetIF[]) => {
        const betsDict = bets.reduce(
            (acc: { [groupId: string]: BetIF[] }, bet: BetIF) => {
                if (!acc[bet.groupId]) {
                    acc[bet.groupId] = []
                }
                acc[bet.groupId].push(bet)

                return acc
            },
            {}
        )

        return Object.values(betsDict)
            .sort((a, b) =>
                String(b[0].createdAt).localeCompare(String(a[0].createdAt))
            )
            .map((bets) => <ReportBetCard key={bets[0].id} bets={bets} />)
    }, [])

    return (
        <div className={styles.content}>
            <div className={sidebarStyles.sidebar}>
                <header>Overview</header>
                <ReportOverview
                    overview={report?.totals}
                    setShowDownloadModal={setShowDownloadModal}
                />
            </div>
            <div className={styles.reports}>
                <div className={styles.header}>
                    <header>{`${
                        period.type === ReportType.THIS_WEEK ||
                        period.type === ReportType.LAST_WEEK
                            ? 'Weekly'
                            : period.type
                    } Report ${period && getHeader(period)}`}</header>
                    <div className={styles.period_selector}>
                        {period.type === ReportType.CUSTOM && (
                            <input
                                type="date"
                                value={UTCToEST(period.start, true)}
                                onChange={(e) =>
                                    setPeriod((p) => ({
                                        ...p,
                                        start: e.target.value
                                            ? ESTToUTC(
                                                  new Date(
                                                      e.target.value +
                                                          'T00:00:00'
                                                  )
                                              )
                                            : p.start,
                                    }))
                                }
                            />
                        )}
                        {period.type === ReportType.CUSTOM && (
                            <input
                                type="date"
                                value={UTCToEST(
                                    new Date(
                                        period.end.getTime() -
                                            24 * 60 * 60 * 1000
                                    ),
                                    true
                                )}
                                onChange={(e) =>
                                    setPeriod((p) => ({
                                        ...p,
                                        end: e.target.value
                                            ? // manually add one day so it fetches inclusive of that day
                                              ESTToUTC(
                                                  new Date(
                                                      new Date(
                                                          e.target.value +
                                                              'T00:00:00'
                                                      ).getTime() +
                                                          24 * 60 * 60 * 1000
                                                  )
                                              )
                                            : p.end,
                                    }))
                                }
                            />
                        )}
                        <Dropdown
                            options={Object.values(ReportType)}
                            onSelect={(option) =>
                                option &&
                                setPeriod(
                                    setPeriodRange(option as ReportType, period)
                                )
                            }
                            value={period.type}
                            defaultValue={ReportType.DAILY}
                        />
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.header}>
                        {!showSummary
                            ? 'Bets'
                            : showSummary === SummaryType.ACCOUNT
                            ? 'Account Summary'
                            : showSummary === SummaryType.SOURCE
                            ? 'Agent Summary'
                            : ''}
                        {report && report.filters && (
                            <ReportFilters
                                filters={report.filters}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={setSelectedFilters}
                                showSummary={showSummary}
                                setShowSummary={setShowSummary}
                                dropdown
                            />
                        )}
                    </div>
                    <div className={styles.bet_card_container}>
                        <div className={styles.filter_buttons}>
                            {report && report.filters && !showSummary && (
                                <ReportFilters
                                    filters={report.filters}
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={setSelectedFilters}
                                    showSummary={showSummary}
                                    setShowSummary={setShowSummary}
                                />
                            )}
                        </div>
                        {page === 1 && isLoading ? (
                            <div className={styles.loading}>
                                <Loader
                                    loading={isLoading}
                                    size={48}
                                    color={'var(--dark1)'}
                                />
                            </div>
                        ) : report && report.bets.length > 0 ? (
                            <div className={styles.scrollable}>
                                {showSummary === SummaryType.ACCOUNT ? (
                                    <>
                                        <ReportSummary
                                            type="Account"
                                            startDate={period.start}
                                            endDate={period.end}
                                            source={selectedFilters.source[0]}
                                            onClick={(account: string) => {
                                                setShowSummary(undefined)
                                                setSelectedFilters((prev) => ({
                                                    ...prev,
                                                    account: [account],
                                                }))
                                            }}
                                        />
                                    </>
                                ) : showSummary === SummaryType.SOURCE ? (
                                    <>
                                        <ReportSummary
                                            type="Source"
                                            startDate={period.start}
                                            endDate={period.end}
                                            onClick={(source: string) => {
                                                setShowSummary(undefined)
                                                setSelectedFilters((prev) => ({
                                                    ...prev,
                                                    source: [source],
                                                }))
                                            }}
                                        />
                                    </>
                                ) : (
                                    report.bets && showBets(report.bets)
                                )}
                                {!report && isLoading ? (
                                    <div className={styles.loading}>
                                        <Loader
                                            loading={isLoading}
                                            size={24}
                                            color={'var(--dark2)'}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {!showSummary &&
                                            page &&
                                            report.bets.length <
                                                report.totals.unique && (
                                                <p
                                                    className={styles.show_more}
                                                    onClick={() =>
                                                        setPage(page + 1)
                                                    }
                                                >
                                                    Show More
                                                </p>
                                            )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <p style={{ padding: '0.5rem 1rem' }}>
                                No bets to show.
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {showDownloadModal && (
                <ReportDownloadModal
                    period={period}
                    filters={report?.filters}
                    selectedFilters={selectedFilters}
                    onClose={() => setShowDownloadModal(false)}
                />
            )}
        </div>
    )
}
