import moment from 'moment-timezone'

export const nowInEST = moment().tz('America/New_York').toDate()

export const UTCToEST = (date: Date, ISO = false): string => {
    return moment
        .utc(date)
        .tz('America/New_York')
        .format(ISO ? 'YYYY-MM-DD' : 'MMM DD, YYYY')
}

export const ESTToUTC = (date: Date): Date => {
    return moment(date).tz('America/New_York', true).utc().toDate()
}
