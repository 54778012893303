import React from 'react'
import styles from './Modal.module.css'

export const Modal = (props: {
    children: React.ReactNode
    onClose?: () => void
}) => {
    const { children, onClose } = props
    return (
        <div
            className={styles.modal_container}
            onMouseDown={(e: React.MouseEvent) => {
                e.stopPropagation()
                onClose && onClose()
            }}
        >
            <div
                className={styles.modal}
                onMouseDown={(e: React.MouseEvent) => {
                    e.stopPropagation()
                }}
            >
                {children}
            </div>
        </div>
    )
}
