import styles from './App.module.css'
import { Header } from './views/Header'
import { ContextProvider } from './utils/context'
import React, { useContext, useEffect } from 'react'
import { Context } from './utils/context'
import { Login } from './views/Login'
import { Main } from './views/Main'
import { Settings } from './views/Settings'
import {
    Navigate,
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from 'react-router-dom'
import { Accounts } from './views/Accounts'
import { Reports } from './views/Reports'
import Loader from 'react-spinners/BounceLoader'
import useIdleTimer from './hooks/useIdleTimer'
import { RightSidebar } from './utils/types'
import { Notifications } from './views/Notifications'
import { BetHistory } from './views/BetHistory'
import {Games} from "./views/Games";

const ProtectedRoute = (props: { children: React.ReactNode }) => {
    const { children } = props
    const { loggedInUser, setLoggedInUser, isRightSidebarOpen } =
        useContext(Context)
    const location = useLocation()
    const isActive = useIdleTimer()

    useEffect(() => {
        // log out the user automatically after one hour of inactivity
        if (!isActive) {
            setLoggedInUser(null)
        }
    }, [isActive, setLoggedInUser])

    if (loggedInUser === null) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    if (loggedInUser === undefined) {
        return (
            <div className={styles.loading_container}>
                <Loader loading={true} size={48} color={'var(--dark1)'} />
            </div>
        )
    }

    return (
        <div className={styles.content}>
            {children}
            {isRightSidebarOpen === RightSidebar.NOTIFICATION ? (
                <Notifications />
            ) : (
                isRightSidebarOpen === RightSidebar.BET_HISTORY && (
                    <BetHistory />
                )
            )}
        </div>
    )
}

export const App = () => {
    return (
        <div className={styles.container}>
            <Router>
                <ContextProvider>
                    <Header />
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/settings"
                            element={
                                <ProtectedRoute>
                                    <Settings />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/accounts"
                            element={
                                <ProtectedRoute>
                                    <Accounts />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                          path="/games"
                          element={
                              <ProtectedRoute>
                                  <Games />
                              </ProtectedRoute>
                          }
                        />
                        <Route
                            path="/reports"
                            element={
                                <ProtectedRoute>
                                    <Reports />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <Main />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </ContextProvider>
            </Router>
        </div>
    )
}
