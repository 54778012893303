import { useState } from 'react'
import { Modal } from '../components/Modal'
import { ReportFilterIF } from '../utils/types'
import styles from './Reports.module.css'
import { ReportFilters } from './ReportFilters'
import { ReportPeriod, downloadCSV, getHeader } from '../utils/reports'
import { FiDownload } from 'react-icons/fi'

export const ReportDownloadModal = (props: {
    period: ReportPeriod
    filters?: ReportFilterIF | null
    selectedFilters: ReportFilterIF
    onClose: () => void
}) => {
    const {
        period,
        filters,
        selectedFilters: defaultSelectedFilters,
        onClose,
    } = props
    const [includeBets, setIncludeBets] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState(
        defaultSelectedFilters
    )
    const [isLoading, setIsLoading] = useState(false)

    return (
        <Modal onClose={onClose}>
            <div className={styles.modal}>
                <header>Download Report</header>
                <div className={styles.checkbox}>
                    <label>
                        <input
                            type="checkbox"
                            checked={includeBets}
                            onChange={(e) => setIncludeBets(!includeBets)}
                        />
                        <span>Include Bets in Report</span>
                    </label>
                </div>
                {includeBets && filters && (
                    <ReportFilters
                        filters={filters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                    />
                )}
                {includeBets && filters && (
                    <ReportFilters
                        filters={filters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        dropdown
                    />
                )}
                <button
                    className={styles.download}
                    onClick={async () => {
                        setIsLoading(true)
                        await downloadCSV(
                            getHeader(period),
                            period.start,
                            period.end,
                            includeBets,
                            selectedFilters
                        )
                        setIsLoading(false)
                    }}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        '...'
                    ) : (
                        <>
                            <FiDownload size={24} /> Download Report
                        </>
                    )}
                </button>
            </div>
        </Modal>
    )
}
