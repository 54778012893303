import { useState, useEffect } from 'react'

const useIdleTimer = (timeout: number = 1000 * 60 * 60 * 2): boolean => {
    const [isActive, setIsActive] = useState<boolean>(true)

    useEffect(() => {
        let timer: NodeJS.Timeout
        const handleActivity = (): void => {
            setIsActive(true)
            clearTimeout(timer)
            timer = setTimeout(() => setIsActive(false), timeout)
        }

        window.addEventListener('mousemove', handleActivity)
        window.addEventListener('keydown', handleActivity)

        timer = setTimeout(() => setIsActive(false), timeout)

        return (): void => {
            window.removeEventListener('mousemove', handleActivity)
            window.removeEventListener('keydown', handleActivity)
            clearTimeout(timer)
        }
    }, [timeout])

    return isActive
}

export default useIdleTimer
