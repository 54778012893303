import React, {
    createContext,
    SetStateAction,
    useEffect,
    useState,
} from 'react'
import {
    checkToken,
    deleteStoredToken,
    getAccounts,
    getGames,
    getSettings,
    getStoredToken,
} from '../services/api'
import {
    AccountScraperIF,
    DropletStatus,
    GameScraperIF,
    OddsIF,
    RightSidebar,
    SettingsWHIF,
} from './types'
import { io, Socket } from 'socket.io-client'
import { Dispatch } from 'react'

export interface ContextIF {
    loggedInUser?: { id: number; role: string } | null
    setLoggedInUser: Dispatch<
        SetStateAction<{ id: number; role: string } | null | undefined>
    >
    league: string
    setLeague: (val: string) => void
    webSocket: Socket | undefined
    games: GameScraperIF[]
    accounts: AccountScraperIF[]
    settings: SettingsWHIF | undefined
    betNotification: boolean
    setBetNotification: Dispatch<SetStateAction<boolean>>
    showStatusNotification: { game: boolean; account: boolean }
    setShowStatusNotification: Dispatch<
        SetStateAction<{ game: boolean; account: boolean }>
    >
    isRightSidebarOpen: RightSidebar | null
    setIsRightSidebarOpen: Dispatch<SetStateAction<RightSidebar | null>>
    odds: OddsIF[]
    setOdds: Dispatch<SetStateAction<OddsIF[]>>
    highlightOddsIds: number[]
}

export const Context = createContext<ContextIF>({} as ContextIF)

export const ContextProvider = (props: { children: React.ReactNode }) => {
    const [loggedInUser, setLoggedInUser] = useState<
        { id: number; role: string } | null | undefined
    >(undefined)
    const [league, setLeague] = useState('')
    const [betNotification, setBetNotification] = useState(false)
    const [webSocket, setWebSocket] = useState<Socket | undefined>(undefined)
    const [gameScrapers, setGameScrapers] = useState<GameScraperIF[]>([])
    const [accountScrapers, setAccountScrapers] = useState<AccountScraperIF[]>(
        []
    )
    const [settings, setSettings] = useState<SettingsWHIF | undefined>(
        undefined
    )
    const [showStatusNotification, setShowStatusNotification] = useState<{
        game: boolean
        account: boolean
    }>({ game: true, account: true })
    const [isRightSidebarOpen, setIsRightSidebarOpen] =
        useState<RightSidebar | null>(null)

    const [odds, setOdds] = useState<OddsIF[]>([])
    const [highlightOddsIds, setHighlightOddsIds] = useState<number[]>([])

    useEffect(() => {
        ;(async () => {
            if (loggedInUser) {
                const accounts = await getAccounts()
                if (accounts) setAccountScrapers(accounts)

                const games = await getGames()
                if (games) setGameScrapers(games)

                const settings = await getSettings()
                if (settings) setSettings(settings)

                if (!process.env.REACT_APP_SERVER_URL) {
                    console.error(
                        'Unable to create websocket. Missing REACT_APP_SERVER_URL!'
                    )
                    return
                }

                const socket = io(process.env.REACT_APP_SERVER_URL, {
                    query: { role: loggedInUser.role, userId: loggedInUser.id },
                })
                setWebSocket(socket)
            } else if (loggedInUser === undefined) {
                const token = getStoredToken()
                if (token && token.token) {
                    try {
                        await checkToken()
                        setLoggedInUser({ id: token.userId, role: token.role })
                    } catch (error) {
                        deleteStoredToken()
                        setLoggedInUser(null)
                    }
                } else {
                    setLoggedInUser(null)
                }
            } else {
                webSocket?.disconnect()
                setWebSocket(undefined)
                setLeague('')
                setGameScrapers([])
                setAccountScrapers([])
                setBetNotification(false)
                setShowStatusNotification({ game: true, account: true })
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser])

    useEffect(() => {
        if (!webSocket) return

        webSocket.on('games', async (data: GameScraperIF[]) => {
            setGameScrapers((prev) => {
                const map = new Map()

                prev.forEach((game) => map.set(game.id, game))
                data.forEach((game) => map.set(game.id, game))

                return Array.from(map.values())
            })

            if (
                data.length > 0 &&
                data.every((g) => g.dropletStatus === DropletStatus.ACTIVE)
            )
                setTimeout(
                    () =>
                        setShowStatusNotification((prev) => ({
                            ...prev,
                            game: false,
                        })),
                    5000
                )
        })
        webSocket.on('odds', async (data: OddsIF[]) => {
            setOdds((prev) => {
                const map = new Map(prev.map((o) => [o.id, o]))
                data.forEach((o) => {
                    map.set(o.id, {
                        ...(map.get(o.id) || o),
                        line: o.line,
                    } as OddsIF)
                })
                return Array.from(map.values()).filter((o) => o.line)
            })
            setHighlightOddsIds((prev) => [...prev, ...data.map((o) => o.id)])
        })

        webSocket.on('accounts', async (data: AccountScraperIF[]) => {
            setAccountScrapers((prev) => {
                const map = new Map()

                prev.forEach((account) => map.set(account.id, account))
                data.forEach((account) => {
                    const existing = map.get(account.id)
                    map.set(
                        account.id,
                        // use existing password because it's decrypted
                        existing
                            ? {
                                  ...existing,
                                  ...account,
                                  password: existing.password,
                              }
                            : account
                    )
                })
                return Array.from(map.values())
            })

            if (
                data.length > 0 &&
                data
                    .filter((a) => a.accountProviders)
                    .flatMap((a) => a.accountProviders)
                    .every((a) => a.dropletStatus === DropletStatus.ACTIVE)
            )
                setTimeout(
                    () =>
                        setShowStatusNotification((prev) => ({
                            ...prev,
                            account: false,
                        })),
                    5000
                )
        })

        webSocket.on('settings', async (data: SettingsWHIF) => {
            setSettings((prev) => ({ ...prev, ...data }))
        })

        return () => {
            webSocket.off('accounts')
            webSocket.off('games')
            webSocket.off('settings')
            webSocket.off('odds')
        }
    }, [webSocket])

    useEffect(() => {
        if (!settings?.scrapersActive) {
            setLeague('')
            setGameScrapers([])
        }
    }, [settings])

    useEffect(() => {
        if (highlightOddsIds.length > 0) {
            const timeout = setTimeout(() => {
                setHighlightOddsIds([])
            }, 1000)

            return () => clearTimeout(timeout)
        }
    }, [highlightOddsIds])

    return (
        <Context.Provider
            value={{
                loggedInUser,
                setLoggedInUser,
                league,
                setLeague,
                webSocket,
                games: gameScrapers,
                accounts: accountScrapers,
                settings,
                betNotification,
                setBetNotification,
                showStatusNotification,
                setShowStatusNotification,
                isRightSidebarOpen,
                setIsRightSidebarOpen,
                odds,
                setOdds,
                highlightOddsIds,
            }}
        >
            {props.children}
        </Context.Provider>
    )
}
