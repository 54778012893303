import { BetIF } from './types'

export const getAccountName = (website: string) =>
    new RegExp(/https?:\/\/(?:www\.)?([a-zA-Z0-9-.]+)\./).exec(website)?.[1] ??
    website

export const getOutcomeAmount = (bet: BetIF) =>
    bet.realOddsLine
        ? bet.realOddsLine < 0
            ? bet.riskAmount ?? bet.amount
            : (bet.riskAmount ?? bet.amount) * (bet.realOddsLine / 100) || 0
        : 0

export const getRiskAmount = (bet: BetIF) =>
    bet.realOddsLine
        ? bet.realOddsLine > 0
            ? bet.riskAmount ?? bet.amount
            : (bet.outcomeAmount ?? bet.amount) / (-100 / bet.realOddsLine) || 0
        : 0

export const formatNumber = (number: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
    return formatter.format(number)
}

export const formatTeam = (awayTeam: string, homeTeam?: string) =>
    `${awayTeam}${homeTeam ? ` @ ${homeTeam}` : ''}`
